<template>
  <CHeader with-subheader>
    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!--      <CIcon name="logo" height="48" alt="Logo"/>-->

      <img
        v-if="$store.state.darkMode"
        src="@/assets/logo/logo_dashboard_sfondo_scuro.svg"
        class="c-sidebar-brand-full"
        height="35"
      />
      <img
        v-else
        src="@/assets/logo/logo_dashboard_sfondo_chiaro.svg"
        class="c-sidebar-brand-full"
        height="35"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard </CHeaderNavLink>
      </CHeaderNavItem>
      <!--      <CHeaderNavItem class="px-3">-->
      <!--        <CHeaderNavLink to="/users" exact>-->
      <!--          Users-->
      <!--        </CHeaderNavLink>-->
      <!--      </CHeaderNavItem>-->
      <!--      <CHeaderNavItem class="px-3">-->
      <!--        <CHeaderNavLink>-->
      <!--          Settings-->
      <!--        </CHeaderNavLink>-->
      <!--      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav>
      <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button @click="() => $store.commit('toggle', 'darkMode')" class="c-header-nav-btn">
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
      <!--
      <CHeaderNavItem class="px-3">
        <button
            class="c-header-nav-btn"
            @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem>

     -->
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
