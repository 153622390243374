<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img v-if="agenzia !== null" :src="agenzia.logo_azienda" class="c-avatar-img" alt="" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>

    <CDropdownItem v-on:click="logout">
      <CIcon name="cil-lock-locked" />
      Cambia account
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeaderDropdownAccnt',
  computed: {
    ...mapGetters('agenzia', ['agenzia']),
  },
  methods: {
    logout() {
      this.$router.push({
        name: 'Login',
      });
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
